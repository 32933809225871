<template>
  <b-modal
    ref="updateInfoModal"
    size="lg"
    :title="$t('products-order.please-confirm')"
    @ok="$emit('callback')"
    @hidden="resetInputs"
    centered
  >
    <p>{{ $t('products-order.confirm-text') }}:</p>
    <div
      v-for="(product, index) in activeProductsGroup.supplierGroup.products"
      :key="index"
      class="mb-3"
    >
      <div class="d-flex align-items-baseline">
        <span>{{ product.quantity }} x {{ product.name }}</span>
      </div>
      <b-form-group
        :label="
          $t('products-order.serial-number') +
          (product.requireSerialNumber ? ' *:' : ':')
        "
        label-cols-sm="2"
        label-align-sm="right"
        class="mt-1"
      >
        <b-form-input
          v-model="product.serial_number"
          :placeholder="$t('products-order.serial-number-placeholder')"
        />
        <small
          class="text-danger"
          v-if="
            product.requireSerialNumber &&
            (!product.serial_number || product.serial_number.trim() === '')
          "
        >
          This field is required.
        </small>
      </b-form-group>
    </div>
    <p class="mt-3">
      {{ $t('products-order.from') }}
      <strong>{{ activeProductsGroup.supplierGroup.name }}</strong>
    </p>
    <b-row>
      <b-col md="6">
        <b-form-group :label="`${$t('products-order.carrier')}:`">
          <CrudMultiSelect
            v-model="activeProductsGroup.carrier"
            endpoint="/data-api/carrier"
            :searchFields="['name']"
            :allowEmptySearch="true"
            trackBy="id"
            label="name"
            :placeholder="`${$t('products-order.required')}`"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="`${$t('products-order.tracking-id')}:`">
          <b-form-input
            v-model="activeProductsGroup.trackingID"
            :placeholder="`${$t('products-order.required')}`"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="`${$t('products-order.date-expected')}:`">
          <DatePicker
            v-model="activeProductsGroup.dateExpected"
            :placeholder="`${$t('products-order.optional')}`"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-checkbox v-model="activeProductsGroup.addComment">
      {{ $t('products-order.add-comment') }}
    </b-form-checkbox>
    <RichTextComments
      v-if="activeProductsGroup.addComment"
      v-model="activeProductsGroup.newComment"
      class="mt-3"
    />
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="primary" @click="ok()" :disabled="!isValid">
        OK
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">
        {{ $t('products-order.cancel') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import RichTextComments from '@gid/vue-common/components/inputs/RichTextComments.vue';

export default {
  components: {
    CrudMultiSelect,
    DatePicker,
    RichTextComments,
  },
  props: {
    activeProductsGroup: Object,
    callback: Function,
  },
  computed: {
    // Only allow confirmation if all products that require a serial number are populated.
    isValid() {
      return this.activeProductsGroup.supplierGroup.products.every(
        (product) => {
          if (product.requireSerialNumber) {
            return product.serial_number && product.serial_number.trim() !== '';
          }
          return true;
        },
      );
    },
  },
  methods: {
    openModal() {
      this.$refs.updateInfoModal.show();
    },
    resetInputs() {
      this.activeProductsGroup.carrier = null;
      this.activeProductsGroup.trackingID = null;
      this.activeProductsGroup.dateExpected = null;
      this.activeProductsGroup.addComment = false;
      this.activeProductsGroup.newComment = null;
    },
  },
};
</script>
