import axios from 'axios';
import moment from 'moment';
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';
import ProductsList from '../components/ProductsList.vue';
import UpdateInfoModal from '../components/UpdateInfoModal.vue';
import { UserRoleEnum } from '@gid/models';

export default function productOrdersMixin({ isProjectsPage }) {
  return {
    components: {
      UpdateInfoModal,
      ProductsList,
    },
    async created() {
      await this.fetchProductOrders();
      await this.checkProductsRequiringSerialNumber(
        this.item,
        this.item.products,
      );
    },
    data() {
      return {
        activeProductsGroup: {
          jobId: isProjectsPage ? null : this.item.job.sfid,
          dateExpected: null,
          carrier: null,
          trackingID: null,
          supplierGroup: {
            id: null,
            name: null,
            products: [],
          },
          productOrder: null,
          addComment: false,
          newComment: null,
        },
        productOrders: [],
      };
    },
    computed: {
      getProductsToOrder() {
        return this.activeProductsGroup.supplierGroup.products
          .filter(({ quantity }) => quantity > 0)
          .map((product) => ({
            productName: product.name,
            quantity: product.quantity,
            product: { id: product.id },
          }));
      },

      getCurrentProductOrder() {
        return (jobApiId, supplierId) =>
          this.productOrders?.find(
            (order) =>
              order.jobApiId === jobApiId && order.supplierId === supplierId,
          );
      },
    },
    methods: {
      async fetchProductOrders() {
        const mapProductOrder = (order) => {
          return {
            id: order?.id,
            jobId: order?.job?.id,
            jobApiId: order?.job?.apiId,
            supplierId: order?.supplier?.id,
            carrier: order?.carrier,
            items: order?.items?.map(({ productName }) => productName),
            url: order?.carrier?.urlPattern?.replace('{ID}', order?.trackingID),
            trackingID: order?.trackingID,
            shipTo: order?.shipTo,
            comment: order?.comments
              ? order.comments[order.comments.length - 1]
              : null,
            dateExpected: order?.dateExpected,
            customerEmail: isProjectsPage
              ? this.item.item?.customers[0]?.email
              : this.item?.customer?.email,
          };
        };

        if (isProjectsPage) {
          const jobIds = this.item.item.jobs.map((job) => job.sfid);
          Promise.all(jobIds.map((id) => this.fetchProductOrdersForJob(id)))
            .then((res) => {
              this.productOrders = res.reduce((acc, cur) => {
                return acc.concat([
                  ...new Set(
                    cur
                      ?.sort(
                        (firstEl, secondEl) =>
                          secondEl.created_at - firstEl.created_at,
                      )
                      .map((order) => mapProductOrder(order)),
                  ),
                ]);
              }, []);
            })
            .catch((error) => new Error(error));
        } else {
          const orderedQuery = RequestQueryBuilder.create({
            search: {
              'job.id': { [CondOperator.IN]: [this.item.sfid] },
            },
          }).query();
          const productOrder = await axios
            .get(`/documents-api/product-orders?${orderedQuery}`)
            .then(({ data }) => data);

          this.productOrders = [
            ...new Set(
              productOrder
                ?.sort(
                  (firstEl, secondEl) =>
                    secondEl.created_at - firstEl.created_at,
                )
                .map((order) => mapProductOrder(order)),
            ),
          ];
        }
      },

      async fetchProductOrdersForJob(job_id) {
        const orderedQuery = RequestQueryBuilder.create({
          search: {
            'job.id': { [CondOperator.IN]: [job_id] },
          },
        }).query();
        return axios
          .get(`/documents-api/product-orders?${orderedQuery}`)
          .then(({ data }) => data);
      },

      async checkProductsRequiringSerialNumber(order, products) {
        const query = {
          filter: [
            {
              field: 'product.id',
              operator: CondOperator.IN,
              value: products.map(({ id }) => id),
            },
            {
              field: 'opportunity.id',
              operator: CondOperator.EQUALS,
              value: this.item.opportunity.sfid,
            },
            {
              field: 'requireSerialNumber',
              operator: CondOperator.EQUALS,
              value: true,
            },
          ],
        };
        const qb = RequestQueryBuilder.create(query).query();
        const { data } = await axios.get(
          `/data-api/product-opportunity/?${qb}`,
        );
        data.forEach((po) => {
          const index = this.item.products.findIndex(
            ({ id }) => id == po.product.id,
          );
          if (index !== -1) {
            this.item.products[index] = {
              ...this.item.products[index],
              requireSerialNumber: true,
            };
          }
        });
      },

      findJobId(jobApiId) {
        return this.item.item.jobs.find((job) => job.api_id === jobApiId)?.sfid;
      },

      async toggleModal(productsBySupplier) {
        await this.fetchProductOrders();

        if (isProjectsPage) {
          this.activeProductsGroup.jobId = this.findJobId(
            productsBySupplier.jobApiId,
          );
        }

        const currentProductOrder = this.getCurrentProductOrder(
          productsBySupplier.jobApiId,
          productsBySupplier.id,
        );

        this.activeProductsGroup.productOrder = currentProductOrder;

        // Initialize the supplier group and extend each product with serial_number and requireSerialNumber.
        this.activeProductsGroup.supplierGroup = {
          ...productsBySupplier,
          products: productsBySupplier.products.map((product) => {
            // Look up the original product from the item to get requireSerialNumber.
            const originalProduct = this.item.products.find(
              (p) => p.id === product.id,
            );
            return {
              ...product,
              // Ensure we have a serial number field.
              serial_number:
                product.serial_number || product.serialNumber || '',
              // Set requireSerialNumber based on the original product.
              requireSerialNumber: originalProduct
                ? originalProduct.requireSerialNumber || false
                : false,
            };
          }),
        };

        if (
          (!this.activeProductsGroup.carrier ||
            !this.activeProductsGroup.trackingID ||
            !this.activeProductsGroup.dateExpected) &&
          currentProductOrder
        ) {
          this.activeProductsGroup.carrier = currentProductOrder?.carrier;
          this.activeProductsGroup.trackingID = currentProductOrder?.trackingID;
          this.activeProductsGroup.dateExpected =
            currentProductOrder?.dateExpected;
        }

        if (
          !this.activeProductsGroup.newComment &&
          currentProductOrder?.comment
        ) {
          this.activeProductsGroup.addComment = true;
          this.activeProductsGroup.newComment = {
            ...currentProductOrder.comment,
          };
        }

        this.$refs.updateInfoModal.openModal();
      },

      async confirmOrder(bvModalEvent) {
        if (
          !this.activeProductsGroup.carrier ||
          !this.activeProductsGroup.trackingID
        ) {
          bvModalEvent.preventDefault();
          this.$bvModal.msgBoxOk(
            'Please make sure both Carrier & Tracking ID fields are populated simultaneously to place the product order',
            {
              title: 'Error',
              okVariant: 'danger',
              headerClass: 'text-danger',
              centered: true,
            },
          );
          return null;
        }

        // Validate required serial numbers
        const allSerialsValid =
          this.activeProductsGroup.supplierGroup.products.every((product) => {
            if (product.requireSerialNumber) {
              return (
                product.serial_number && product.serial_number.trim() !== ''
              );
            }
            return true;
          });
        if (!allSerialsValid) {
          bvModalEvent.preventDefault();
          this.$bvModal.msgBoxOk(
            'Please enter the required serial numbers for all products marked as required.',
            {
              title: 'Error',
              okVariant: 'danger',
              headerClass: 'text-danger',
              centered: true,
            },
          );
          return null;
        }

        let method = 'post';
        let endPath = '';

        if (this.activeProductsGroup.productOrder) {
          method = 'patch';
          endPath = `/${this.activeProductsGroup.productOrder.id}`;
        }

        await axios[method](`/documents-api/product-orders${endPath}`, {
          job: { id: this.activeProductsGroup.jobId },
          supplier: { id: this.activeProductsGroup.supplierGroup.id },
          shipTo: 'customer',
          carrier: this.activeProductsGroup.carrier
            ? { id: this.activeProductsGroup.carrier.id }
            : undefined,
          trackingID: this.activeProductsGroup.trackingID,
          dateExpected: this.activeProductsGroup.dateExpected
            ? moment(this.activeProductsGroup.dateExpected)
                .startOf('day')
                .add(12, 'hours')
                .toISOString()
            : null,
          items: this.getProductsToOrder,
          comment: this.activeProductsGroup.addComment
            ? {
                message: this.activeProductsGroup.newComment.message,
                visibleForRoles: [
                  UserRoleEnum.ADMIN,
                  UserRoleEnum.BRAND,
                  UserRoleEnum.CUSTOMER,
                  UserRoleEnum.PARTNER,
                ],
              }
            : undefined,
          createdByContact: { id: this.$store.getters.user.contact.sfid },
          serials: this.activeProductsGroup.supplierGroup.products
            .filter(
              ({ serial_number, serialNumber }) =>
                (serial_number && serial_number.trim() !== '') ||
                (serialNumber && serialNumber.trim() !== ''),
            )
            .map((product) => ({
              productId: product.id,
              serialNumber: product.serial_number || product.serialNumber,
            })),
        });

        await this.fetchProductOrders();
      },
    },
  };
}
